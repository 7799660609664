<template>
  <div :class="$style.container">
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="saveDeliverySettings"
        >Сохранить настройки</el-button
      >
    </div>
    <h1>Настройки дней доставки</h1>
    <div :class="$style.sidebar">
      <div :class="$style.instruction">
        <h2>Инструкция</h2>
        <ul :class="$style.list">
          <li>Выберите даты, и вам будет предложено создать новое событие</li>
          <li>Доступно перетаскивание и изменение размера событий</li>
          <li>Щелкните на событие, чтобы удалить его</li>
        </ul>
      </div>
      <div :class="$style.settings">
        <h2>Настройки</h2>
        <div :class="$style.setting">
          <div>
            <h4>Недоступные дни недели</h4>
          </div>
          <el-select
            v-model="deliveryRestricted"
            multiple
            filterable
            placeholder="Выберите дни недели"
            @change="
              changeDeliveryRestricted(
                $options.UNAVAILABLE_DELIVERY_DAYS_TYPES
                  .DELIVERY_WEEK_RESTRICTED,
              )
            "
            :class="$style.select"
          >
            <el-option
              v-for="item in unavailableDaysWeekOptions"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div :class="$style.setting">
          <div>
            <h4>Недоступные дни недели поставщика</h4>
          </div>
          <el-select
            v-model="supplierDeliveryRestricted"
            multiple
            filterable
            placeholder="Выберите дни недели"
            @change="
              changeDeliveryRestricted(
                $options.UNAVAILABLE_DELIVERY_DAYS_TYPES
                  .SUPPLIER_DELIVERY_WEEK_RESTRICTED,
              )
            "
            :class="$style.select"
          >
            <el-option
              v-for="item in unavailableDaysWeekOptions"
              :key="item.value"
              :label="item.title"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div :class="$style.setting">
          <div>
            <h4>Время доставки (по Москве)</h4>
          </div>
          <el-time-select
            placeholder="Начало"
            v-model="expressDeliveryTimeRange.from"
            :picker-options="{
              ...timePickerOptions,
              maxTime: expressDeliveryTimeRange.to,
            }"
            :clearable="false"
          >
          </el-time-select>
          <span>{{ '\u2013' }}</span>
          <el-time-select
            placeholder="Конец"
            v-model="expressDeliveryTimeRange.to"
            :picker-options="{
              ...timePickerOptions,
              minTime: expressDeliveryTimeRange.from,
            }"
            :clearable="false"
          >
          </el-time-select>
        </div>
        <div :class="$style.setting">
          <div>
            <h4>Задержка доставки поставщиком</h4>
          </div>
          <el-input
            min="0"
            type="number"
            v-model="supplierDeliveryDayDelay"
            placeholder="Введите кол-во дней"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        calendarOptions.hiddenDays.length !== unavailableDaysWeekOptions.length
      "
      :class="$style.wrapperCalendar"
    >
      <div :class="$style.statuses">
        <div
          v-for="item in $options.EVENT_TYPES"
          :key="item.value"
          :class="$style.status"
        >
          <div :class="$style.color" :style="`background-color: ${item.color}`">
            {{ item.status }}
          </div>
          <p>{{ item.title }}</p>
        </div>
      </div>
      <FullCalendar
        ref="calendar"
        :class="$style.calendar"
        :options="calendarOptions"
      />
    </div>
    <modal name="new-event" :class="$style.modal">
      <div :class="$style.title">
        <h3>Создание события</h3>
        <div :class="$style.iconWrapper" @click="handleCloseModal">
          <Icon name="close" :class="$style.icon" />
        </div>
      </div>
      <div :class="$style.content">
        <div :class="$style.item">
          <p>Дата события:</p>
          <p>
            начало {{ selectInfo.startStr }} - конец {{ selectInfo.endStr }}
          </p>
        </div>
        <div :class="$style.item">
          <p>Тип события:</p>
          <el-select
            v-model="selectInfo.type"
            filterable
            placeholder="Выберите тип события"
            :class="$style.select"
          >
            <template v-for="item in $options.EVENT_TYPES">
              <el-option
                v-if="item.isAddEvent"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              >
              </el-option>
            </template>
          </el-select>
        </div>
      </div>
      <el-button type="primary" :class="$style.button" @click="saveEvent"
        >Сохранить</el-button
      >
    </modal>
  </div>
</template>

<script>
import delivery from '@/delivery'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import ruLocale from '@fullcalendar/core/locales/ru'
import Icon from '@/components/atoms/Icon.vue'
import { v4 as uuidv4 } from 'uuid'
import notifications from '@/mixins/notifications'
import { debounce } from '@/helpers/debounce.js'
import {
  UNAVAILABLE_DELIVERY_DAYS_TYPES,
  EVENT_TYPES,
} from '@/constants/deliverySettings'

export default {
  UNAVAILABLE_DELIVERY_DAYS_TYPES,
  EVENT_TYPES,
  components: {
    FullCalendar,
    Icon,
  },
  mixins: [notifications],
  data() {
    return {
      supplierDeliveryDayDelay: 0,
      deliveryRestricted: [],
      supplierDeliveryRestricted: [],
      unavailableDaysWeekOptions: [
        { title: 'воскресенье', value: 0 },
        { title: 'понедельник', value: 1 },
        { title: 'вторник', value: 2 },
        { title: 'среда', value: 3 },
        { title: 'четверг', value: 4 },
        { title: 'пятница', value: 5 },
        { title: 'суббота', value: 6 },
      ],
      timePickerOptions: {
        start: '00:00',
        step: '00:15',
        end: '23:59',
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
        headerToolbar: {
          right: 'prev,next today',
          left: 'title',
        },
        initialView: 'multiMonthYear',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.handleEventChange,
        eventResize: this.handleEventChange,
        hiddenDays: [],
        locales: [ruLocale],
        timeZone: 'Europe/Moscow',
        eventTextColor: '#ffffff',
        events: [],
        selectAllow: this.handleEventAllow,
      },
      currentEvents: [],
      selectInfo: {},
      expressDeliveryTimeRange: {
        from: '00:00',
        to: '18:00',
      },
    }
  },

  async mounted() {
    await this.getDeliverySettings()
    this.changeDeliveryRestricted(
      UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED,
    )
    this.changeDeliveryRestricted(
      UNAVAILABLE_DELIVERY_DAYS_TYPES.SUPPLIER_DELIVERY_WEEK_RESTRICTED,
    )
  },

  methods: {
    changeDeliveryRestricted(type) {
      this.$refs.calendar.getApi().getEventById(type)?.remove()

      this.$refs.calendar.getApi().addEvent({
        id: `${type}`,
        type,
        title:
          type === UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED
            ? 'НДН'
            : 'НДНП',
        color:
          type === UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED
            ? '#423189'
            : '#37c53a',
        daysOfWeek:
          type === UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED
            ? this.deliveryRestricted
            : this.supplierDeliveryRestricted,
        startRecur: `${new Date().getFullYear()}-01-01`,
        endRecur: `${new Date().getFullYear() + 1}-01-01`,
      })
    },
    showProhibitionMessage() {
      this.showNotification(
        'Извините, нельзя создать событие на прошедшее время',
        'error',
      )
    },
    async getDeliverySettings() {
      const { value } =
        await delivery.PaymentServiceCore.DeliverySettingsActions.get()

      if (value.restrictedDays?.weekdays?.length) {
        this.deliveryRestricted = value.restrictedDays.weekdays
      }

      if (value.restrictedDays?.supplierWeekdays?.length)
        this.supplierDeliveryRestricted = value.restrictedDays.supplierWeekdays

      if (value.expressDeliveryTimeRange)
        this.expressDeliveryTimeRange = value.expressDeliveryTimeRange

      if (value.supplierDeliveryDayDelay)
        this.supplierDeliveryDayDelay = value.supplierDeliveryDayDelay

      this.calendarOptions.events = value.restrictedDays.events?.map(
        (event) => {
          const eventType =
            EVENT_TYPES.find((eventType) => eventType.type === event.type) ||
            EVENT_TYPES[0]

          return {
            title: eventType.status,
            start: new Date(event.from),
            end: new Date(event.to),
            allDay: true,
            id: uuidv4(),
            color: eventType.color,
            type: eventType.type,
          }
        },
      )
    },
    async saveDeliverySettings() {
      const data = {
        restrictedDays: {
          events: this.currentEvents
            .map((event) => ({
              from: event.start?.toISOString(),
              to: event.end?.toISOString(),
              type: event.extendedProps.type,
            }))
            .filter(
              (event) =>
                event.type !==
                  UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED &&
                event.type !==
                  UNAVAILABLE_DELIVERY_DAYS_TYPES.SUPPLIER_DELIVERY_WEEK_RESTRICTED,
            ),
          weekdays: [...this.deliveryRestricted],
          supplierWeekdays: [...this.supplierDeliveryRestricted],
        },
        expressDeliveryTimeRange: this.expressDeliveryTimeRange,
        supplierDeliveryDayDelay: Number(this.supplierDeliveryDayDelay),
      }

      const { error } =
        await delivery.PaymentServiceCore.DeliverySettingsActions.save(data)

      if (!error)
        this.showNotification('Настройки успешно сохранены', 'success')
    },
    isExistingEvent(selectInfo) {
      const existingEvent = this.currentEvents.find((event) => {
        if (
          selectInfo.start < event.end &&
          selectInfo.end > event.start &&
          selectInfo.id !== event.id
        ) {
          return event
        }
      })
      return !!existingEvent
    },
    handleDateSelect(selectInfo) {
      if (this.isExistingEvent(selectInfo)) {
        const existingEvents = this.currentEvents.filter((event) => {
          if (
            selectInfo.start < event.end &&
            selectInfo.end > event.start &&
            selectInfo.id !== event.id
          ) {
            return event
          }
        })

        if (
          existingEvents.find(
            (x) =>
              x.extendedProps.type ===
              UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_RESTRICTED,
          )
        ) {
          this.$message({
            type: 'error',
            message: 'Доставка недоступна - невозможно добавить события',
          })
        } else {
          selectInfo.type = ''
          selectInfo.id = uuidv4()
          this.selectInfo = selectInfo

          this.$modal.show('new-event')
        }

        return
      }
      selectInfo.type = ''
      selectInfo.id = uuidv4()
      this.selectInfo = selectInfo

      this.$modal.show('new-event')
    },
    handleEventChange(eventDropInfo) {
      eventDropInfo.revert()
    },
    handleEventClick(clickInfo) {
      if (
        confirm(
          `Вы действительно хотите удалить событие '${clickInfo.event.title}, начало ${clickInfo.event.startStr} - конец ${clickInfo.event.endStr}'`,
        )
      ) {
        clickInfo.event.remove()
      }
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    handleCloseModal() {
      this.selectInfo = {}
      this.$modal.hide('new-event')
    },
    saveEvent() {
      if (!this.selectInfo?.type) return

      const eventType = EVENT_TYPES.find(
        (eventType) => eventType.type === this.selectInfo.type,
      )
      const calendarApi = this.selectInfo.view.calendar
      calendarApi.unselect()

      const existingEvents = this.currentEvents.filter((event) => {
        if (
          this.selectInfo.start < event.end &&
          this.selectInfo.end > event.start &&
          this.selectInfo.id !== event.id
        ) {
          return event
        }
      })

      if (
        !existingEvents.find(
          (x) => x.extendedProps.type === this.selectInfo?.type,
        )
      ) {
        const newEvent = {
          title: eventType.status,
          start: this.selectInfo.startStr,
          end: this.selectInfo.endStr,
          allDay: this.selectInfo.allDay,
          id: this.selectInfo.id,
          color: eventType.color,
          type: eventType.type,
        }

        calendarApi.addEvent(newEvent)
      }

      this.$modal.hide('new-event')
    },
    handleEventAllow(selectInfo) {
      if (
        selectInfo.start.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      ) {
        debounce(this.showProhibitionMessage, 500)()

        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" module>
.container {
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .modal > div:last-child {
    h3 {
      text-align: center;
    }
    padding: 2rem;
    .title {
      display: flex;
      align-items: center;
      .iconWrapper {
        cursor: pointer;
        display: flex;
        margin-left: auto;
        flex-shrink: 0;
        flex-grow: 0;
      }
      h3 {
        display: flex;
        flex-grow: 1;
        justify-content: center;
      }
    }
    .content {
      margin-top: 2rem;
      .item {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        p:first-child {
          margin-right: 0.5rem;
        }
      }
    }
    .button {
      display: block;
      margin: 4rem auto 0;
    }
  }
  h1 {
    text-align: center;
  }
  min-height: 100%;
  .sidebar {
    .instruction {
      padding: 2rem;
      .list {
        margin-top: 1rem;
      }
    }
  }
  .settings {
    padding: 2rem;
    .setting {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      h4 {
        width: 20rem;
      }
      .select {
        width: 80%;
      }
    }
  }
  .wrapperCalendar {
    margin-top: 1rem;
    flex-grow: 1;
    padding: 3rem;
    .statuses {
      display: flex;
      justify-content: center;
      .status {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        .color {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          min-width: 3rem;
          min-height: 3rem;
          text-align: center;
          line-height: 2.7rem;
          margin-right: 0.5rem;
          color: $white;
        }
      }
    }
    .calendar {
      max-width: 90rem;
      margin: 0 auto;
    }
  }
}
</style>
